<template>
  <b-form-group class="check-actions" :label="label" label-for="check">
    <div v-for="op in options" :key="op.value">
      <b-form-checkbox class="custom-switch d-inline-block" :key="op.value" :checked="isChecked(op.value)" @change="checked => updateValue(op.value, checked)" size="md" :disabled="disabled" switch>
        <p class="info-checkbox">{{ op.text }}</p>
      </b-form-checkbox>
      <b-collapse :visible="isChecked(op.value)">
        <template v-if="op.options?.length > 0">
          <div class="pl-4">
            <b-form-checkbox class="custom-switch d-inline-block" :checked="isSubCheckedAll(op)" @change="checked => selectAll(op, checked)" size="md" :disabled="disabled" switch>
              <p class="info-checkbox">Todos</p>
            </b-form-checkbox>
          </div>
          <div v-for="sub in op.options" :key="sub.value" class="pl-4">
            <b-form-checkbox class="custom-switch d-inline-block" :checked="isSubChecked(op.value, sub.value)" @change="checked => updateSubValue(op.value, sub.value, checked)" size="md" :disabled="disabled" switch>
              <p class="info-checkbox">{{ sub.text }}</p>
            </b-form-checkbox>
          </div>
        </template>
        <div v-if="op.groups?.length > 0" class="d-flex bd-highlight my-1 pl-4">
          <div v-for="(group, i) in op.groups" :key="`group_${i}`" class="flex-fill bd-highlight">
            <template v-if="group.options?.length > 0">
              <label class="d-block">{{ group.label }}</label>
              <div v-for="sub in group.options" :key="sub.value">
                <b-form-checkbox class="custom-switch d-inline-block" :checked="isSubChecked(op.value, sub.value)" @change="checked => updateSubValue(op.value, sub.value, checked)" size="md" :disabled="disabled" switch>
                  <p class="info-checkbox">{{ sub.text }}</p>
                </b-form-checkbox>
              </div>
            </template>
          </div>
        </div>
      </b-collapse>
    </div>
  </b-form-group>
</template>
  
<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    value: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      internalValue: [...this.value]
    };
  },
  created() {
    this.internalValue = [...this.value];
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.internalValue = val;
      }
    }
  },
  methods: {
    isChecked(value) {
      return this.internalValue.some(item => item.value == value);
    },
    isSubChecked(parent, value) {
      const parentItem = this.internalValue.find(item => item.value == parent);
      if (parentItem?.sub) {
        return parentItem.sub.some(sub => sub.value == String(value));
      }

      return false;
    },
    updateValue(value, checked) {
      if (!checked) {
        const index = this.internalValue.findIndex(item => item.value == value);
        this.internalValue.splice(index, 1);
      }
      else {
        const createIndex = {
          value: value,
        };

        const option = this.options.find(op => op.value == value);
        if (option.options?.length > 0) {
          createIndex.sub = option.options.map(sub => ({ value: sub.value }));
        }
        else if (option.groups?.length > 0) {
          createIndex.sub = option.groups.flatMap(group =>
            group.options.map(sub => ({ value: sub.value }))
          );
        }

        this.internalValue.push(createIndex);
      }

      this.$emit('input', this.internalValue);
    },
    updateSubValue(parentValue, value, checked) {
      const index = this.internalValue.findIndex(item => item.value == parentValue);
      if (index !== -1) {
        if (!checked) {
          const subIndex = this.internalValue[index].sub.findIndex(sub => sub.value == value);
          this.internalValue[index].sub.splice(subIndex, 1);
        } else {
          this.internalValue[index].sub.push({ value: value });
        }
      }

      this.$emit('input', this.internalValue);
    },
    selectAll(option, checked) {
      const index = this.internalValue.findIndex(item => item.value == option.value);
      if (index !== -1) {
        if (!checked) {
          this.internalValue[index].sub = [];
        } else {
          this.internalValue[index].sub = option.options.map(sub => ({ value: sub.value }));
        }
        
        this.$emit('input', this.internalValue);
      }
    },
    isSubCheckedAll(option) {
      const index = this.internalValue.findIndex(item => item.value == option.value);
      if (index !== -1) {
        return option.options.every(sub => this.internalValue[index].sub.some(subItem => subItem.value == sub.value));
      }

      return false;
    },
  }
}
</script>
<style lang="scss" scoped>
.check-actions {

  > :global(label.d-block) {
    font-size: 12px !important;
    font-weight: 600;
    color: #6B7280;
  }

  > :global(label p) {
    color: #111827;
  }

  .custom-switch {
    padding-left: 2.25rem;

    :global(.info-checkbox) {
      font-family: "Inter", sans-serif; 
      font-weight: 500;
      top: 2px;
      position: relative;
    }

    :global(.custom-control-input:checked ~ .custom-control-label::before) {
      background-color: #0854C5;
      border-color: #0854C5;
    }
  }
}
</style>
  